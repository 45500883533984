<mat-sidenav-container class="main-container tw-h-full uw-layout">
  <mat-sidenav [mode]="sidenavMode" [opened]="sidenav" class="sidenav  ">
    <div class="uw-layout-header">
      <a class="uw-nav-logo tw-flex-grow" routerLink="/">
        <img [src]="logoUrl" [alt]="brand?.name || 'VOXR'" />
      </a>
      <button mat-icon-button (click)="sidenav=!sidenav"><mat-icon>menu</mat-icon></button>
    </div>
    <div class="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto">
      <nav class="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-py-2 ">
        @switch (area) {
        @case('admin') {
        <a routerLink="/admin/status" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> {{brand?.name || 'VOXR'}} Status
        </a>
        <a routerLink="/admin/users" routerLinkActive="uw-active">
          <i class="pi pi-users"></i> Manage Users
        </a>
        @if(user?.roles?.includes('super-admin')){
        <!-- <a routerLink="/admin/workspaces" routerLinkActive="uw-active">
          <i class="pi pi-building"></i> Manage Workspaces
        </a> -->
        <a routerLink="/admin/campaigns" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Manage Campaigns
        </a>
        <a routerLink="/admin/reports" routerLinkActive="uw-active">
          <i class="pi pi-chart-pie"></i> Reports
        </a>
        <a routerLink="/admin/calls" routerLinkActive="uw-active">
          <i class="pi pi-phone"></i> Call Logs
        </a>
        }
        <a routerLink="/admin/vapi" routerLinkActive="uw-active" class="tw-mt-auto">
          <i class="pi pi-link"></i> Manage VAPI
        </a>
        @if(user?.roles?.includes('super-admin')){
        <a routerLink="/admin/plans" routerLinkActive="uw-active">
          <i class="pi pi-link"></i> Manage Plans
        </a>
        <a routerLink="/admin/integrations" routerLinkActive="uw-active">
          <i class="pi pi-link"></i> Default Integrations
        </a>
        <a routerLink="/admin/blueprints" routerLinkActive="uw-active">
          <i class="pi pi-book"></i> Built-in Blueprints
        </a>
        <a routerLink="/admin/campaign-templates" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Campaign Templates
        </a>
        <a routerLink="/admin/logs" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> System Logs
        </a>
        }

        }
        @case('account') {
        <a routerLink="/account/status" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Account Status
        </a>
        @if(user?.isApproved){
        <a routerLink="/account/phone" routerLinkActive="uw-active">
          <i class="pi pi-phone"></i> Phone Settings
        </a>
        <a routerLink="/account/workspaces" routerLinkActive="uw-active">
          <i class="pi pi-shop"></i> Workspaces
        </a>
        }

        }
        @case('reseller') {
        <a routerLink="/reseller/status" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Reseller Status
        </a>
        @if(user?.resellerConfig?.whiteLabel?.enabled) {
        <a routerLink="/reseller/whitelabel" routerLinkActive="uw-active">
          <i class="pi pi-cog"></i> White-Label Settings
        </a>
        @if(user?.resellerConfig?.whiteLabel?.domains?.length) {

        @if(externalResellerDomain){
        <a [href]="externalResellerDomain+'/reseller/plans'"><i class="pi pi-shop"></i> Manage Plans</a>
        <a [href]="externalResellerDomain+'/reseller/users'"><i class="pi pi-users"></i> Manage Users</a>
        <a [href]="externalResellerDomain+'/reseller/blueprints'"><i class="pi pi-book"></i> Built-in Blueprints</a>
        <a [href]="externalResellerDomain+'/reseller/campaign-templates'"><i class="pi pi-chart-line"></i> Campaign
          Templates</a>
        <a [href]="externalResellerDomain+'/reseller/phone'"><i class="pi pi-phone"></i> Phone Settings</a>
        <a [href]="externalResellerDomain+'/reseller/workspaces'"><i class="pi pi-shop"></i> Workspaces</a>
        } @else {
        <a [routerLink]="'/reseller/plans'" routerLinkActive="uw-active">
          <i class="pi pi-shop"></i> Manage Plans</a>
        <a [routerLink]="'/reseller/users'" routerLinkActive="uw-active">
          <i class="pi pi-users"></i> Manage Users</a>
        <a [routerLink]="'/reseller/blueprints'" routerLinkActive="uw-active">
          <i class="pi pi-book"></i> Built-in Blueprints</a>
        <a [routerLink]="'/reseller/campaign-templates'" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Campaign Templates</a>
        <a [routerLink]="'/reseller/phone'" routerLinkActive="uw-active">
          <i class="pi pi-phone"></i> Phone Settings</a>
        <a [routerLink]="'/reseller/workspaces'" routerLinkActive="uw-active">
          <i class="pi pi-shop"></i> Workspaces</a>
        }

        }
        }
        }
        @case('workspace') {
        <a wRouterLink="/dashboard" routerLinkActive="uw-active">
          <i class="pi pi-home"></i> Dashboard
        </a>
        <a wRouterLink="/campaigns" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Campaigns
        </a>
        @if(['owner', 'admin'].includes(workspaceRole!)) {

        @if(!workspace?.onboarded?.includes('completed')){
        <a wRouterLink="/onboarding" routerLinkActive="uw-active">
          <i class="pi pi-star"></i> Onboarding
        </a>
        }
        <a wRouterLink="/assistants" routerLinkActive="uw-active">
          <i class="pi pi-bolt"></i> Assistants
        </a>
        <div class="tw-flex-grow"></div>
        <a wRouterLink="/calls" routerLinkActive="uw-active">
          <i class="pi pi-phone"></i> Call Logs
        </a>
        <a wRouterLink="/blueprints" routerLinkActive="uw-active">
          <i class="pi pi-book"></i> Blueprints
        </a>
        <a wRouterLink="/integration" routerLinkActive="uw-active">
          <i class="pi pi-link"></i> Integrations
        </a>
        <a wRouterLink="/members" routerLinkActive="uw-active">
          <i class="pi pi-users"></i> Workspace Members
        </a>
        <a wRouterLink="/settings" routerLinkActive="uw-active">
          <i class="pi pi-cog"></i> Workspace Settings
        </a>
        @if(user?.roles?.includes('super-admin')){
        <a wRouterLink="/reports" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Reports
        </a>
        }
        } @else { <div class="tw-flex-grow"></div> }

        @if(workspaces?.length && workspaces!.length > 1){
        <div class="p-fluid">
          <p-dropdown [options]="workspaces" optionValue="_id" optionLabel="name" placeholder="Select Workspace"
            [ngModel]="workspace?._id" (onChange)="setWorkspace($event.value)"></p-dropdown>
        </div>
        }

        }
        }

      </nav>

    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="tw-flex tw-flex-col tw-flex-1 tw-flex-grow tw-min-w-0">
      <div class="uw-layout-header">
        @if(!sidenav){
        <button mat-icon-button (click)="sidenav=!sidenav" class="tw-p-4 tw-m-2 tw-cursor-pointer">
          <mat-icon>menu</mat-icon>
        </button>
        }
        @switch (area) {
        @case('workspace') {
        <div class="tw-flex tw-gap-2 tw-items-center tw-px-4">
          @if(workspace) {
          <h5>{{workspace.name}}</h5>
          @if(workspace.accountStatus != 'active') {
          <p-tag severity="danger" [pTooltip]="workspace.accountStatus">Account Disabled</p-tag>
          @if(workspace.ownerId == user?._id) {
          <a routerLink="/account/onboarding" class="tw-ml-2">Activate Account</a>
          }
          }
          }
        </div>
        <app-transfer-availability />
        }
        @case('account') {
        <div>Account Settings</div>
        }
        @case('reseller') {
        <div>Reseller Settings</div>
        }
        @case('admin') {
        <div>Admin Area</div>
        }
        }
        <app-user-status class="tw-ms-auto" />
      </div>
      <div class="uw-layout-content tw-px-2 " [ngClass]="{'surface-dark': area=='admin'}">
        <router-outlet />
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>