import { Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { UserService } from './services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../environments/environment';
import { HealthService } from './services/health.service';
import { TooltipModule } from 'primeng/tooltip';
import { filter, firstValueFrom } from 'rxjs';
import { WorkspaceService } from './services/workspace.service';
import { AppService, SSO_REDIRECT_PARAM } from './services/app.service';
import { WhiteLabelConfig } from 'voxr-shared';
import { SafeHtml } from '@angular/platform-browser';
import { BrandLoadingComponent } from "./components/general/brand-loading/brand-loading.component";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, CommonModule,
    ToastModule, TooltipModule,
    BrandLoadingComponent],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private app = inject(AppService);
  private userService = inject(UserService);
  private workspaceService = inject(WorkspaceService);
  private healthService = inject(HealthService);
  private snackBar = inject(MatSnackBar);

  loading = true;
  deployment = environment.deployment;
  serverId?: Object;
  brand?: WhiteLabelConfig & { resellerId: string; };
  brandCss?: SafeHtml;
  icon!: string;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(async () => await this.load());
    this.app.newVersion$.pipe(takeUntilDestroyed()).subscribe(() => this.newVersion());
    this.checkQueries();
  }

  async ngOnInit() {
    await this.load();
    this.serverId = await this.healthService.serverId();
  }

  newVersion() {
    const snackbarRef = this.snackBar.open('A new version of the app is available. Refresh to update.', 'Refresh');
    snackbarRef.onAction().subscribe(() => location.reload());
  }

  private async load() {
    if (!this.brand) {
      const ssoRedirect = this.route.snapshot.queryParams[SSO_REDIRECT_PARAM];

      this.brand = ssoRedirect ? await this.app.getResellerByOrigin(new URL(ssoRedirect).origin) : await this.app.reseller();

      const { brandCss, icon } = this.app.setBranding(this.brand);
      this.icon = icon;
      this.brandCss = brandCss;
    }

    const user = await firstValueFrom(this.userService.user$);
    if (user) await firstValueFrom(this.workspaceService.workspaces$);

    document.querySelector('body')?.classList.add('uw-ready');
    this.loading = false;
  }

  private checkQueries() {
    try {
      if (location.search.includes('iframe')) {
        const path = localStorage.getItem('iframe-pathname');
        if (path) {
          const urlTree = this.router.parseUrl(path);
          delete urlTree.queryParams['iframe'];
          this.router.navigateByUrl(urlTree, { replaceUrl: true });
        }
        this.router.events.pipe(filter(e => e instanceof NavigationEnd))
          .subscribe(e => localStorage.setItem('iframe-pathname', e.url));
      }
      if (location.search.includes('impersonate=')) {
        localStorage.setItem('impersonate', location.search.split('impersonate=')[1].split('&')[0]);
        const urlTree = this.router.parseUrl(location.pathname);
        delete urlTree.queryParams['impersonate'];
        this.router.navigateByUrl(urlTree, { replaceUrl: true });
      }
    } catch (e) {
      console.error(e);
    }
  }


}
